.paper {
    width: 100%;
    overflow: hidden;
  }
  .table-row-height {
    height: 3.75rem!important;
  }
  .table-heading {
    font-weight: bold!important;
    font-size: 11px!important;
    color: #4A4A4A!important;
  }
  .table {
    background-color: #F6F7F9 !important ;
    color: #333 !important;
    font-size: 16px !important;
  }
  
  .backgroundColor {
    background-color: red
  }

  .min-height {
    min-height: 15rem !important;
  }