/* Ensure the table container is scrollable */
.table-container {
  position: relative;
  overflow-y: auto; /* Ensure vertical scroll is applied */
  height: 400px; /* Set a specific height for the table container if necessary */
  /* Optional: Set padding or margin for better layout */
}

/* Custom scrollbar styles */
.table-container::-webkit-scrollbar {
  width: 6px; /* Narrow the scrollbar */
}

.table-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Optional: Set the track color */
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Set thumb color */
  border-radius: 5px;
}

/* On hover, change thumb color */
.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}

/* Add padding inside cells if needed */
.MuiTableCell-root {
  padding: 8px 16px;
}

.aligncell-top {
  align-items: baseline;
  vertical-align: baseline;
}

.table {
  background: #f5f5f5 !important ;
  color: #000000 !important;
  font-size: 16px !important;
}


