.container {
  margin-top: 1.5rem;
}

.render-component-container {
  margin-top: 1.25rem !important;
  margin-left: 1.25rem;
}
.min-width-650 {
  min-width: 650px !important;
}

.table-height {
  max-height: 600px;
  overflow-y: auto;
  margin-top: 10px;
}
.table-paper {
  max-height: 300;
  overflow-y: auto;
  margin-top: 10px;
}

.width-30 {
  width: 30.75rem;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.dialog-heading {
  font-size: 20px !important;
  font-weight: 800 !important;
  color: #000;
}

.display {
  display: flex;
}
.button-container {
  width: 100%;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  padding: 0 !important;
}

.head-container {
  align-content: center;
  height: "20px";
  font-size: "20px" !important;
  margin-right: "22px";
  padding: "20px";
}

.basic-details-heading {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0rem;
  color: #24262c;
  opacity: 1;
  font-weight: 400;
}

.box-width {
  width: 30.75rem;
}

.ellipses-width {
  width: 12rem;
}

.input-top {
  margin-top: 1rem;
}

.margin-right-16 {
  margin-right: 1rem;
}
.margin-top-16 {
  margin-top: 1rem;
}
.margin-top-8 {
  margin-top: 0.5rem;
}
.margin-top-32 {
  margin-top: 2rem;
}
.margin-top-26 {
  margin-top: 1.625rem;
}

.margin-bottom-16{
margin-bottom: 1rem;
}
.heading {
  font: normal normal normal 16px/21px;
  letter-spacing: 0px;
  color: #24262c;
  opacity: 1;
  margin-top: 1rem;
  font-weight: normal;
}

.button-container-end {
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 26px;
  margin-right: 1.25rem;
}

.button-border {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  opacity: 1;
  padding-right: 14px;
  margin-right: 1.4rem;
  display: flex;
  align-items: center;
}

.button-border2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  opacity: 1;
  padding-right: 14px;
  display: flex;
  align-items: center;
}

.progress-bar ul {
  list-style: none;
  height: 100%;
}
.progress-bar ul li {
  padding: 0.7rem 1rem;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 17px;
}
.remove-left-margin {
  margin-left: none !important; /* Adding !important to override any other CSS */
}

@media (max-width: 576px) {
  .progress-bar ul {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
}

.flex-switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.margin-top-20 {
  margin-top: 1.25rem;
  margin-left: 0.25rem;
}
.margin-top-20b{
  margin-top: 1.25rem;
}

.table-row-heading {
  font-weight: bold !important;
  letter-spacing: 0px !important;
  color: #4a4a4a !important;
  font-size: 11px !important;
}

.table-val {
  color: #24262c !important;
  font-weight: normal !important;
  font-size: 13px !important;
}

.display-flex {
  display: flex;
}

.grid-columns {
  direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 8.75rem;
 
}

.review-heading {
  color: #24262c;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
}

.review-val {
  color: #24262c;
  font-size: 14px;
  font-weight: normal;
}

.val-width-140 {
  width: 8.75rem;
}

.val-width-120 {
  width: 0.05rem;
}

.val-width {
  width: 5.5rem;
}

.margin-top-12 {
  margin-top: 0.8rem;
}
.margin-top-30 {
  margin-top: 1.8rem;
}

.divider {
  width: 100%;
  padding: 10px;
}

.padding-bottom-review {
  padding-bottom: 3.34rem;
}

.review-top {
  padding-top: 2.18rem;
}

.heading-basic {
  color: #24262c;
  font-weight: normal;
  font-size: 1rem;
  margin-top: 1.625rem;
}
/* .button-container {
  width: 100%;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
} */

.headings {
  font-size: 12px;
  font-weight: 600;
  color: #24262c;
  margin-bottom: 10px;
}

.icon {
  display: flex;
  align-items: center;
}

.padding-right-5{

  padding-right: 4.4rem;
}
.margin-left {
  margin-left: 1rem;
}
.margin-top-icon {
  margin-top: 2px;
}
