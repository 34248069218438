.dashboard-header {
  float: left;
  font-size: 20px;
  font-weight: medium;
  color: #24262c;
}

.matched-font-color {
  color: darkgreen;
}
.table-container {
  max-height: 500px;
  overflow-y: auto;
}
.summary-container {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.width {
  width: 100%;
}
.min-height {
  min-height: 25rem !important;
}

.padding {
  padding: 10px;
}
.notebookSummaryCard{
  padding:10px 0px 10px 10px;
  font-size: 12px;
    line-height: 22px;
}
.notebookSummaryCard td{
  padding:8px;
}
.notebook-header{
  margin: 10px 5px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
    justify-content: space-between; 
}
.refresh-icon {
  display: flex;
  justify-content: end;
}

.horizontal-width {
  max-width: 12.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-heading {
  font-weight: bold !important;
  font-size: 11px !important;
  color: #4a4a4a !important;
}

.grid-css {
  align-items: center;
  justify-content: center;
}
.paper {
  width: 100%;
  overflow: hidden;
}

.box-css {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-val {
  font-weight: normal !important;
  font-size: 13px !important;
  color: #24262c !important;
  text-transform: capitalize;
  box-shadow: 0px 1px 0px #e8e8e8 !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.margin-right {
  margin-right: 2rem;
}
.margin-bottom {
  margin-bottom: 1rem;
}

.table {
  overflow: hidden;
  width: 100%;
}

.table-row-height {
  height: 3.75rem !important;
}

.margin-top-8 {
  margin-top: 12rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.align-items {
  align-items: center;
  margin-left: 1rem;
  display: flex;
}

/* .refresh-icon{
    display: flex;
    justify-content: space-between;
  } */
.srch-flex {
  display: inline-flex;
}
.flex-center{
  display: flex;
  align-items: center;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.ellipses-width {
  width: 12rem;
}
.ellipses-width-18 {
  width: 18rem;
}
.link{
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.notebook-totals-card  td{
  padding: 12px;

}
.notebook-totals-card  th{
  padding: 8px;

}
.notebook-changes-container{
  height: 80vh
}
