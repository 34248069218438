.heading {
  color: #24262c !important;
  font-size: 18px !important;
  padding-bottom: 21px;
}
.box {
  background: #f5f4f4 0% 0% no-repeat padding-box !important;
  border-radius: 11px;
  width: 180px;
  height: 75px;
  margin-right: 16px;
}
.card-content {
  text-align: center;
  padding: 15px 0px 24px 0px !important;
}
.botom-text-font {
  color: #5f7281 !important;
  font-size: 20px !important;
}
.paper-padding {
  padding: 20px !important;
}
.maxHeight {
  max-height: 440;
}

.font {
  color: #11171c !important;
  font-size: 30px !important;
}
.top-font {
  color: #24262c !important;
  font-size: 16px !important;
  padding-top: 19px;
  padding-left: 12px;
  font-weight: medium;
}
.table {
  background-color: #f6f7f9 !important ;
  color: #333 !important;
  font-size: 16px !important;
}
.val-font {
  color: #24262c !important;
  font-weight: 300;
  font-size: 13px !important;
  padding-left: 12px;
}
.display {
  display: flex;
}

.display-centre {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 14px !important;
}

.card-content-min-height {
  height: 7.8rem;
}
.card-content-min-height:hover {
  background: #d3d3d333;
  cursor: pointer;
}

.card-content-NotClickable {
  height: 7.8rem;
}

.card-content-NotClickable:hover {
  background: #d3d3d333;
}

.download {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.margin-bottom-16{
  margin-bottom: 1rem;
}
