.button-container {
  width: 100%;
  bottom: 0;
  right: 0;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  padding: 1rem 1rem 1rem 0rem;
}
.margin-0 {
  margin: 0 !important;
}
.margin-left {
  margin-left: 1rem;
  margin-right: 1rem;
}
.width-ellipses {
  max-width: 28rem;
}

.button-border {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  opacity: 1;
  display: inline-block; /* Display as inline-block */
  padding-right: 14px;
  margin-right: 1.4rem;
  width: 20rem;
}
.min-width {
  min-width: 380px !important;
}
.min-width-650 {
  min-width: 650px !important;
}
.font-size {
  font-size: 1.2rem;
}
.padding {
  padding: 10px;
}
.button-border2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  opacity: 1;
  display: inline-block; /* Display as inline-block */
  padding-right: 14px;
  margin-right: 1.4rem;
  width: 24rem;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.padding-top-4 {
  padding-top: 0.4rem;
}
.icon {
  display: flex;
  align-items: center;
}

.table-container {
  max-height: 480px;
  overflow-y: auto;
}

.panel-padding {
  padding: 0.5rem !important;
}

.head-container {
  align-content: center;
  height: "20px";
  font-size: "20px" !important;
  margin-right: "22px";
  padding: "20px";
}
.float-right {
  display: flex;
  justify-content: flex-end;
  /* padding-bottom: 1rem; */
}

.basic-details-heading {
  text-align: left;
  font-size: 20px !important;
  letter-spacing: 0rem;
  color: #24262c;
  opacity: 1;
  font-weight: 400;
}

.padding-left-2 {
  display: flex;
  justify-content: center;
}
.margin-top-32 {
  margin-top: 2rem;
}
.margin-right-8 {
  margin-right: 1rem;
}
.margin-right-10 {
  margin-right: 2rem;
}
.select-refresh {
  margin-left: auto;
  display: flex;
  padding-bottom: 0.5rem;
  align-items: center;
}
.display-align {
  display: flex;
  align-items: center;
}
.display {
  display: flex;
  align-items: center;
}
.margin-top-16 {
  margin-top: 1.5rem;
}
.margin-left-16 {
  margin-left: 1rem;
}
.margin-left-8 {
  margin-left: 0.5rem;
}
.scrollContainer {
  max-height: 26.5rem;
  overflow-y: auto;
}
.margin-top-8 {
  margin-top: 0.8rem;
}
.divider {
  border-left: 1px solid black;
  height: 100%;
}
.container {
  display: flex;
  margin-top: 1rem;
}

.show-section {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ebeaed;
  padding: 10px;
}
.display-centre {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
}
.dropdown-section {
  margin-left: 20px;
  padding-top: 1rem;
}

select {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}
.margin-top-26 {
  margin-top: 1.625rem;
}

.nowrap {
  text-wrap: nowrap;
}

.headings {
  font-size: 12px;
  font-weight: 600;
  color: #24262c;
  margin-bottom: 10px;
}

.srch-flex {
  display: inline-flex;
  align-items: center;
}
.input-box {
  display: flex;
  margin-right: 0.5rem;
  height: 2.2rem;
  width: 22rem;
}
.catlog-table-title {
  padding: 0.5rem 0.5rem 0.5rem 0rem;
}
.display-column-left{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.div-flex{
  justify-content: space-between !important;
}
.table-heading{
  white-space: nowrap;
}

.table-cell-Dbfs{
  max-width: 9.8rem;
}

.margintop-08{
  margin-top: 0.8rem !important;
}
