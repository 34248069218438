.table-val {
    font-weight: normal!important;
    font-size: 13px!important;
    color: #24262C!important;
    text-transform: capitalize;
    box-shadow: 0px 1px 0px #E8E8E8!important;
    background: #FFFFFF 0% 0% no-repeat padding-box!important;
  }
  .table-row-height {
    height: 3.75rem!important;
  }

  .table-font-color {
    font-weight: normal!important;
    font-size: 13px!important;
    color: darkgreen!important;
    text-transform: capitalize;
    box-shadow: 0px 1px 0px #E8E8E8!important;
    background: #FFFFFF 0% 0% no-repeat padding-box!important;

  }