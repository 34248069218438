.button-container {
  width: 100%;
  height: 90px;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  padding: 20px;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.table {
  max-height: 600px;
  overflow-y: auto;
  margin-top: 20px;
}
