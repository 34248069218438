.errr {
  color: red;
}
.navigation-buttons button {
  text-transform: uppercase;
  background: #004f9f;
  border: none;
  font-weight: bold;
  color: #fff;
}
.navigation-buttons button:disabled {
  background: #4fa6fe;
}
.full-Width-Btn{
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  font-weight: bold;
  background: #004f9f;
}
.full-Width-Btn button:disabled {
  background: #4fa6fe;
}
.textAllign{
  text-align: center;
}