.button-container {
  width: 100%;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  /* padding: 0rem 1rem 1rem 0rem; */
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.display {
  display: flex;
  padding-bottom: 1rem;
}

/* form {
  height: 34px;
} */

.table-container {
  max-height: 500px;
  overflow-y: auto;
}

.basic-details-heading {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0rem;
  color: #24262c;
  opacity: 1;
  font-weight: 400;
}
.float-right {
  display: flex;
  justify-content: flex-end;
}
.input-box {
  display: flex;
  margin-right: 0.5rem;
  width: 22rem;
}

.srch-flex {
  display: inline-flex;
  align-items: center;
}
