.dashboard {
  /* height: 120%; */
  margin: 0 auto;
  /* overflow: hidden; */
  font-family: muli, sans-serif;
  padding-top: 4rem !important;
  padding-bottom: "3rem";
  min-height: 100vh;
}
p,
li,
ul,
h5,
h2 {
  padding: 0;
  margin: 0;
}
.dashboard-header {
  width: 100%;
  background-color: #24262c;
  padding: 1rem 1rem;
  height: 68px;
  margin: 0 auto;
  color: #ffffff;
}
.dashboard-header h5 {
  font-size: 22px;
  letter-spacing: 0;
  color: #fff;
  text-transform: uppercase;
}
.horizontal-line {
  width: 2px;
  height: 35px;
  background-color: #fff;
  margin: 0 1rem;
}
.logout-button {
  background: none;
  border: none;
  color: #fff;
}
.dashboard-user-actions {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.dashboard-user-actions li {
  margin: auto 0.5rem;
  padding: 0;
}
.dashboard-section {
  background-color: #fff;
  /* padding: 1rem 1.5rem; */
  width: 100%;
  font-family: "Lato", sans-serif;
}

@media (max-width: 1200px) {
  .dashboard {
    width: 1200px;
  }
}
.dashboard-header-logout {
  display: flex;
  justify-content: flex-end;
}

.dashboard-padding {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  /* height: 100%; */
  min-height: 680px;
}

.displayCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
