.terminate {
  width: 50%;

  background-color: #fff;
  overflow: hidden;
}
.terminate-header {
  display: flex;
  justify-content: space-between;
}
.terminate-header button {
  border: none;
  background: none;
}
.terminate-body {
  padding: 1rem;
  margin: 1rem 0;
}
.box-width {
  width: 58.75rem;
}
.font-heading {
  margin-top: 1.62rem;
  font-weight: normal;
  font-size: 16px;
  color: #24262C;
}
.navigation-buttons button {
  text-transform: uppercase;
  background: #004f9f;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  color: #fff;
  padding: 1rem 1.5rem;
}
.navigation-buttons button:disabled {
  background: #4fa6fe;
}

.cloud-id {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  padding: 10px 10px 10px 15px;
}

.dropdown {
  padding: 20px 20px 20px 10px;
  border: 1px #d6d6d4 solid;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}

.labels {
  margin: 20px;
}

.errorMessage {
  color: red;
  font-size: 14px;
}

.errorInput {
  border: 1px solid red;
}
.terminateErr {
  color: red;
  font-size: 30px;
  text-align: center;
  margin: 20px;
}
.details {
  background-color: #23366d;
  color: #fff;
  padding: 1rem;
}
.close-terminate {
  border: none;
  background: none;
  color: #fff;
  font-size: bold;
}
.margin-top-14 {
  margin-top: 14px;
}
.margin-top-34 {
  margin-top: 2.12rem;
}
.render-component-container {
  margin-top: 1.25rem !important;
  margin-left: 1.25rem;
}
.dropdown-button {
  font-size: 1rem;
}
.radioStyle {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  opacity: 1;
  white-space: nowrap;
  width: 140px;
  font-size: 13px !important;
}
.marginLandR {
  margin-right: 30px !important;
  margin-left: 0px !important;
}
.proceedBtn {
  border: 1px solid #f5f4f4 !important;
  border-radius: 23px !important;
  opacity: 1;
}

.margin-bottom-12 {
  margin-bottom: 0.8rem;
}
.button-container-end {
  position: fixed;
  bottom: 1.87rem;
  display: flex;
 
  background-color: white;
  width: 100%;
}
