.MuiSvgIcon-root.MuiStepIcon-root.Mui-completed {
  color: green;
}
.MuiStepConnector-root > .MuiStepConnector-line {
  font-size: x-small;
  height: 17px;
  min-height: 17px;
}
/* .MuiTableRow-head {
  background-color: #23366d;
  color: white;
  text-decoration: white;
} */
/* th.MuiTableCell-head {
  color: white;
  background-color: #23366d;
} */
.MuiDialogTitle-root {
  color: white;
  background-color: #23366d;
}
.MuiDialogTitle-root button {
  color: white;
}