.displayBlock {
  display: block;
}

.heading {
  font-weight: bold;
  font-size: 17px;
  color: #ffffff;
  padding-left: 1rem;
  letter-spacing: 0px;
}
.customClass {
  opacity: 0;
  color: #fff;
}

.open {
  opacity: 1;
}

.font {
  font-size: 18px;
}
.padding-top {
  padding-top: 75px !important;
}
.color {
  color: white;
}
.selectedItemBackground {
  background-color: #457ECF;
}
.drawer {
  background-color: #24262C;
  width: 55px;
}

.grid-columns {
  direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 8.75rem;
  align-items: center;
}
.app-bar {
  background-color: #24262C!important;
  box-shadow: none;
  height: 3.5rem;
}
.box {
  font-size: 21px;
  margin-right: 8px;
  margin-top: 5px;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.padding-top-6 {
  padding-top: 5.7rem;
}
