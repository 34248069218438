.border-radio {
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    margin-right: 1rem;
    padding-right: 1rem;
    font-weight: normal;
    font-size: 14px;
    color: #24262C
    
}


.font-class {
   

    color: #24262C !important;
    font-weight: normal !important;
    font-size: 14px !important;
    letter-spacing: 0px !important;
}

.icon{
    display: flex;
    align-items: center;
}