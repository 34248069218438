.login-div {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.login-v {
  box-sizing: border-box;
  box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 20%), 2px 2px 2px 2px rgb(0 0 0 / 14%),
    2px 1px 2px 2px rgb(0 0 0 / 12%);
  width: 30%;
  padding: 10px 25px 25px 25px;
}
.border-radius {
  border-radius: 16px !important;
}
.border-20 {
  border-radius: 20px !important;
}
.font-20 {
  font-size: 20px;
}
.height {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}
.width {
  width: 100%;
}

:host {
  display: flex;
  justify-content: center;
  margin: 100px 0px;
}

.form-control {
  width: 100%;
  min-width: 300px;
}
.login-form input[type="text"] {
  border: none;
  border-bottom: 2px solid lightgray !important;
}
.login-v input[type="password"] {
  border: none;
  border-bottom: 2px solid lightgray !important;
}
h1 {
  font-size: 30px;
  display: flex;
  justify-content: center;
}
.login-text {
  font-size: 22px;
  letter-spacing: 0;
  padding: 15px;
  background-color: #24262c !important;
  color: #ffffff !important;
}

.err {
  color: red;
  margin-bottom: 10px;
  font-size: larger;
  font-weight: bolder;
  background-color: rgb(245, 185, 185);
  padding: 10px;
  margin: 5px;
  margin-left: 0px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
}

.versionInfo {
  font-weight: 600;
  color: #ffffff;
  position: fixed;
  bottom: 1rem;
  right: 2rem;
}
