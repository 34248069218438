.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
}
.header {
  font-size: larger;
  font-weight: 500;
}
.display {
  display: flex;
  align-items: center;
}
.button-border {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  margin-right: 2rem;
  padding-right: 1rem;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  margin-top: 1rem;
  min-width: 20rem;
  color: #24262c;
}
.display-flex {
  display: flex;
}
.margintop-8 {
  margin-top: 0.5rem;
}
.margintop-1 {
  margin-top: 1rem;
}
.ellipses-width-18 {
  width: 18rem;
}
.flex-center {
  display: flex;
  align-items: center;
}
.table-container {
  max-height: 28.75rem;
  overflow-y: auto;
}
.table-container2 {
  margin-top: 2rem;
  max-width: 64rem;
  max-height: 28.75rem;
  overflow-y: auto;
}
.marginTop-4{
  margin-top: 3.5rem;
}
