.box {
    box-shadow: 0px 5px 10px #19273810;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    height: 18.75rem;
    width: 37.625rem;
    align-content: center;
    text-align: center;
}

.box-centre {
    display: flex;
    justify-content: center;
}

.text-font {
    font-weight: 300;
    font-size: 14px;
    color: #24262C;

}