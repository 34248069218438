.alert-container {
    display: flex;
    align-items: center; 
  }
  
  .alert-content {
    flex-grow: 1;
  }
  .close-icon {
    float: right;
  }

  .padding-left {
    padding-left: 1rem;
  }
  .margin-left {
    margin-left: 2rem;
  }
  .width {
    width: 100%;
  }