* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.grid-container {
  position: relative;
  width: 100%;
  height: 62vh;
  overflow: scroll;
  cursor: grab; /* Shows a grabbing hand cursor while dragging */
}

.grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: transform 0.3s ease;
}

.component {
  width: 200px;
  height: 100px;
  background-color: lightblue;
  margin: 50px;
  text-align: center;
  line-height: 100px;
  border-radius: 10px;
  font-weight: bold;
}

.zoom-buttons {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
}

.zoom-buttons button {
  font-size: 16px;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  cursor: pointer;
  background-color: #457ecf;
  color: white;
  border: none;
  border-radius: 16px;
}
