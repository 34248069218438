.heading {
    font: normal normal normal 16px/21px;
    letter-spacing: 0px;
    color: #24262c;
    opacity: 1;
    margin-top: 1rem;
    font-weight: normal;
    align-items: center;
    ;
  }
  .margin-left {
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  .display {
    display: flex;
    align-items: center;
  }

  .headings {
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: #24262c;
    align-items: center;
  }
  