.button-border {
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    margin-right: 2rem;
    padding-right: 1rem;
    font-weight: normal;
    font-size: 14px;
    display: flex;
    margin-top: 1rem;
    min-width: 16rem;
    max-width: fit-content;
    color: #24262c;
  }