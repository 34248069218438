.header {
  /* height: 122px;  
  padding-left: 20px;
  padding-top: 14px;
  padding-right: 1rem; */
  padding: 20px;
  background: #ffff;
  margin-bottom: 1rem;
}
.val-width-140 {
  width: 14rem;
}
.review-val {
  color: #24262c;
  font-size: 14px;
  font-weight: normal;
}
.font {
  font-size: 22px;
}
.color {
  color: #A9A9A9
}

.icon {
  font-size: 31px;
  margin-right: 6px;
}
.headingpadding {
  padding: 15px 0 8px 0;
}
.displayleft {
  display: flex;
  float: left;
}

.refreshicon {
  display: flex;
  justify-content: space-between;
}

.grid-columns {
  direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 15rem;
  align-items: center;
}
.ellipses-width {
  width: 12rem;
}
.width-val-4 {
  width: 4rem;
}
.width-val-24 {
  width: 34rem;
}

.review-heading {
  color: #24262c;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
}

.startUCBtn {
  margin-right: 16px !important;
  border-radius: 22px !important;
  width: 190px !important;
  opacity: 1;
  background: #457ecf 0% 0% no-repeat padding-box;
}
.ucCatlogBtn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #457ecf;
  border-radius: 22px !important;
  text-transform: capitalize !important;
  opacity: 1;
  color: #457ecf;
  width: 160px;
}
.downloadBtn {
  background: #ffffff;
  color: #457ecf;
  width: 42px !important;
  height: 42px !important;
  border: 1px solid #447ed0;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.summaryHead {
  text-align: left;
  font: normal normal normal 16px / 21px Graphik;
  letter-spacing: 0px;
  color: #d57f00;
  opacity: 1;
  font-size: 20px;
  width: 80px;
  padding-bottom: 5px;
  border-bottom: 1px solid;
}
.breadcrumb {
  /* clear: left !important;
  float: left !important; */
  margin-top: 8px;
  margin-bottom: 14px;
  font-size: 14px;
}

.navigationButton {
  text-transform: none !important;
  font-size: 14px;
  padding-block: unset !important;
  padding: none !important;
}

.buttonWithoutPadding{
  text-transform: none !important;
  font-size: 14px;
  padding: 0 !important;    
  padding-block: 0 !important;

}
