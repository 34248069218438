.headingContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
}
.header{
    font-size: larger;
    font-weight: 500;
}
.display {
    display: flex;
    align-items: center;
  }
  .lineageModal{
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    height: 80vh;
    overflow: scroll;
  }
  .padding-1{
    padding: 1rem;
}