.dropdown {
    height: 37px;
    width: 100%;
    border-color: lightgray;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* .dropdown > button {
    width: 300px;
  } */
  
  /* .dropdown-toggle::after {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  } */
  .input-container {
    position: relative;
    width: 100%;
  }
  
  .input {
    border: 1px solid #c0c0c0;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 9px 8px;
  }
  .label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    pointer-events: none;
  }
  .input,
  .label .text {
    font-family: "Segoe UI";
    font-size: 16px;
  }
  
  .label .text {
    font-size: 12px;
    transform: translate(0, -50%);
    background-color: white;
    padding-left: 4px;
    padding-right: 10px;
    color: rgba(0, 0, 0, 0.6);
  }
  .invalidMsg {
    margin-left: 14px;
    font-size: 0.75rem;
    letter-spacing: 0.03333rem;
    line-height: 1.66rem;
    color: #d32f2f;
  }
  button[type="button"] {
    text-transform: capitalize;
    /* padding-top: 10px;
    padding-bottom: 10px; */
  }
  
  td {
    color: #484848 !important;
    font-size: 14px !important;
    /* border: 1px solid rgb(237, 237, 237); */
  }
  
  th {
    color: #8B8B8B !important;
    font-size: 13px !important;
  }
  .hide-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .splitword {
    word-wrap: break-word;
    word-break: break-all;
  }
  .fixed-layout-table {
    table-layout: fixed;
  }
  .governance-table {
    table-layout: fixed;
    width: 200% !important;
  }
  .blue-text {
    color: #23366d;
  }
  