.margin-left-16 {
    margin-left: 1rem;
  }

  .margin-right-16 {
    margin-right: 1rem;
  }

  .icon {
    display: flex;
    align-items: center;
  }