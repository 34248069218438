body {
  margin: 0;
  padding:0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F4F4;
  height:100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root
{
  width:100%;
  height:100%;
  overflow-y:auto;
  background-color: #F5F4F4;
  scrollbar-width: thin;
}
td
{
  vertical-align:middle;
}
th
{
  vertical-align:middle;
}

