.lineage-dashboard {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  height: 4.5rem;
  border: 1px solid #f2f2f2;
  background-color: #f2f2f2;
  padding-left: 0.6rem;
}

.cross {
  display: flex;
  justify-content: space-between;
}

.search-box-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 42rem;
}

.search-label {
  background-color: #000;
  height: 2.5rem;
  color: #ffffff;
  font-size: 14px;
  font-family: "Graphik", sans-serif;
  border-radius: 10px 0 0 10px;
  width: 6.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Make the dropdown, search box, and button the same height */
.search-box,
.dropdown,
.search-button {
  height: 2.5rem;
  padding: 5px 10px; /* Padding for spacing */
  font-size: 16px; /* Ensure consistent font size */
  border-radius: 0 10px 10px 0;
  border: 1px solid #ffffff;
  /* Border for all elements */
}

/* Dropdown specific styling */
.dropdown {
  width: 150px; /* Set width for dropdown */
}

/* Search box specific styling */
.search-box {
  border-radius: 0 0 0 0 !important;
  width: 200px; /* Set width for search box */
}

/* Search button specific styling */
.search-button {
  width: 40px; /* Fixed width for button */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #007bff; /* Button background */
  color: white; /* Button text color */
}

.search-button i {
  font-size: 20px; /* Icon size inside the button */
}

/* Scrollable container for the job boxes */
.lineage-content {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 1600px;
  height: 500px;
  position: relative; /* Ensure arrows are positioned relative to this container */
}

.common-entity-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

h2 {
  font-size: 18px;
  color: #333;
  margin: 0;
}

.close-button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #888;
}

.close-button:hover {
  color: #f00;
}

.common-entity-icons {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 15px;
}

.common-entity-icons svg {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.common-entity-icons svg:hover {
  transform: scale(1.2);
}

ul {
  list-style: none;
  padding-left: 0;
}

ul li {
  padding: 5px 0;
  font-size: 14px;
  color: #555;
}

.no-data-message {
  font-size: 18px;
  color: #888;
  text-align: center;
  padding: 20px;
}

.common-entity-heading {
  margin-bottom: 5px;
}

.right-panel h3 {
  font-size: 18px;
  color: #333;
}

.right-panel h4 {
  font-size: 16px;
  color: #007bff;
  margin-top: 10px;
}

.right-panel ul {
  list-style-type: none;
  padding-left: 0;
}

.right-panel ul li {
  margin: 5px 0;
  color: #555;
}

.common-entity-icons {
  display: flex;
  justify-content: start;
  gap: 20px;
}

.right-panel .common-entity-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-panel .close-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #000;
  font-size: 20px;
  padding: 0;
}

.jobs-container {
  display: flex;
  justify-content: flex-start; /* Align the boxes to the left */
  align-items: flex-start; /* Center vertically within the container */
  flex-direction: row;
  width: 100%;
  padding: 10px;
  position: relative; /* Important: to make arrows align with jobs */
}

.job-box {
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  opacity: 1;
  width: 14.5rem; /* Width remains the same */
  margin: 8px; /* Reduce margin for tighter spacing */
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items from the top */
  align-items: flex-start; /* Left-align the content inside */
  transition: height 0.3s ease; /* Smooth transition for height */
}

.expandable-content {
  margin-top: 8px; /* Smaller margin to keep things compact */
}

.expandable-item {
  margin: 4px 0; /* Smaller margin for each item */
  cursor: pointer;
  font-size: 14px;
  color: #007bff;
  display: flex;
  align-items: center;
}

.expandable-item:hover {
  text-decoration: underline;
}

.expandable-item i {
  margin-right: 6px; /* Adjust space between icon and text */
}

/* Center the "starting" job box vertically */
.left {
  align-self: center; /* Center vertically */
}

/* Right job boxes should be aligned to the left */
.related-job-boxes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
}

.spacer {
  width: 100px; /* Space between provided job and related jobs */
}

.error {
  color: red;
  margin-top: 20px;
}

/* Arrows styling */
.arrow {
  position: absolute;
  z-index: 1;
  visibility: hidden; /* Hide arrows by default */
  pointer-events: none; /* Prevent arrows from interfering with user clicks */
}

.arrow.visible {
  visibility: visible;
  stroke: #000; /* Default black color */
  fill: none;
}

.arrow.visible {
  visibility: visible; /* Make sure arrows are not hidden */
  stroke: black; /* Make sure the stroke color is set */
  fill: none; /* Make sure there's no fill */
  transition: all 0.3s ease; /* Optional: smooth transition */
}
.arrow line {
  stroke-width: 2;
}

.arrow line[stroke="red"] {
  stroke: red;
}

svg .arrowhead {
  fill: red;
}

svg .arrowhead {
  marker-end: url(#arrowhead);
}

.marker-head {
  fill: red;
}

.common-entity {
  color: red;
}
.common-class {
  background-color: #bfd9f5;
}

.job-box.expanded .job-box-details {
  display: block;
}

.job-box-details {
  padding: 10px;
  display: none;
}

.category {
  border-bottom: 1;
  display: flex;
  cursor: pointer;
  margin-bottom: 10px;
}

.category p {
  margin-left: 10px;
}

.spacer {
  height: 50px;
}

.right-panel {
  z-index: 10;
  overflow-y: auto;
  border: 1px solid #ccc;
  height: 100%;
  position: sticky; /* Make it stick to the right */
  top: 10%; /* Offset from top of screen */
  width: 100%; /* Adjust width as per your preference */
  flex-shrink: 0; /* Prevent shrinking */
}

.icons-container {
  padding-top: 2px;
  background-color: #f7f7f7;
  display: flex;
  padding-left: 0% !important;
  margin-bottom: 20px;
  overflow-x: auto;
}

.icons-container img {
  height: 60px;
  width: 37px;
}

.icon {
  width: 60px;
  height: 37px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
}

.icon p {
  font-size: 12px;
}

.icon img {
  width: 1rem;
  height: 1rem;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  word-break: break-word;
  text-align: justify;
}

.job-box-header {
  border: 1px solid #d5d5d5;
  border-radius: 6px;
  opacity: 1;
  background: #eaf3ff;
  font-size: 14px;
  font-family: "Graphik", sans-serif;
  padding: 4px;
  width: inherit;
}

.iconbutton {
  background: transparent;
  margin-right: 7px;
}

.iconMui {
  padding: 1px;
  color: #6495ed;
  margin-right: 3px;
  font-size: xx-large !important;
  margin-top: 0.5rem;
}
.jobiconbutton {
  border-radius: 2px;
}
.job-box.left {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  opacity: 1;
}
.job-box.related {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  opacity: 1;
}

.job-box.right {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  opacity: 1;
  width: 13rem;
}

.job-box.right p {
  padding: 3px;
  margin-left: 9px;
  font-size: 16px;
}
.arrow {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  pointer-events: none;
}

.arrow.visible {
  visibility: visible;
}

.related-job-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.job-box-icon {
  display: inline;
  margin-bottom: 10px;
}

.job-box p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.truncate-text {
  max-width: 11rem; /* Make sure the text box doesn't grow beyond the available width */
  display: inline-block; /* To allow truncation */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.truncate-text-18 {
  max-width: 18rem; /* Make sure the text box doesn't grow beyond the available width */
  display: inline-block; /* To allow truncation */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Show the full text on hover */
.job-box p:hover {
  color: #007bff;
  cursor: pointer;
}

.entityjob-box-header {
  display: flex;
  padding-left: 3px;
  width: 100%;
  background-color: #eaf3ff;
  border-bottom: 1px solid #d5d5d5;
}

.entityjob-box-header img {
  height: 1.6rem;
}
.entityjob-box-header h6 {
  font-family: "Graphik", sans-serif;
  font-weight: 200;
}

.entityjob-box-header p {
  font-weight: bold;
}

.panel-header {
  padding: 1rem;
  font-family: "Graphik", sans-serif;
  align-content: center;
  height: 4.5rem;
  background-color: #fafafa;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
}

.panel-right-head {
  padding-left: 1rem;
  color: #2965b9;
  font-size: 16px;
  height: 2.25rem;
  font-family: "Graphik", sans-serif;
}

.job-box-header img {
  display: flex;
  align-items: center;
  border-radius: 20px;
  /* height: 100%; */
}

.job-box-header {
  display: flex;
}
