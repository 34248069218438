.button-container {
    width: 100%;
    height: 90px;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }

  .flex-end {
    display: flex !important;
    justify-content: flex-end !important;
  }

  .popover {
    display: flex !important;
    align-items: left !important;
  }

  .dialog-heading {
    font-size: 18px!important;
    font-weight: 600!important;
    color: #24262c;
  }
  .icon {
    position: absolute !important;
    right: 8 !important; 
    top: 8 !important; 
  }
  .icon-button {
    position: absolute !important;
    right: 25 !important;
    left: auto !important;
    top: 8 !important
  }
  