.pagination-container {
    /* position: fixed;
    bottom: 0;
    left: 18%;
    transform: translateX(-50%);
    z-index: 1000;
    padding: 8px;
    display: flex;
    align-items: center; */
    /* background: #FFFFFF;
    height: 5rem;
    width: max-content; */
    background: #FFFFFF;
    padding-top: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.font {
    font-weight: normal;
    font-size: 14px;
    color: #24262C;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 0;
}

.current-page {
    border: 1px solid #D57F00 !important;
    border-radius: 8px;
    background: #FFFFFF !important;
}

  .page {
    list-style: none;
    display: flex;
    padding: 0;
  }
  
  .pageBtn {
    cursor: pointer;
    display: flex;
  }
  
  .pageBtn.active {
    color: #000;
  }
  
  .pageNumbers {
    margin: 0 5px;
    padding: 10px 14px;
    cursor: pointer;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
  }
  
  .pageNumbers.active {
    border: 1px solid #D57F00;
    background: #fff;
  }
  
  .pageDots {
    padding: 10px 14px;
  }

  .disabledBtn {
    opacity: 0.5;
    font-size: 14px !important;
  }